import Axios from "../Config/basics"
import { ChangeClassType } from "./interfaceType"

interface HeaderType<T=string>{
    userId:T|number;
    token:T;
}

interface BodyType<T=number>{
    pageNo:T;
    pageSize:T;
}

/**
 * 选择入学时间
*/
export function GetEnter( headers:HeaderType ):Promise<any>{
    return(
        Axios({
            url:"/dateYear/list",
            method:"POST",
            headers,
            data:{}
        })
    )
}

/**
 * 选择学院列表
*/
export function GetCollega( headers:HeaderType,body:{ schoolCode:string|undefined;inShcoolYearCode:string|undefined; } ):Promise<any>{
    return(
        Axios({
            url:"/schoolCollege/list4schoolAndInShcoolYearCode",
            method:"POST",
            headers,
            data:body
        })
    )
}

/**
 * 选择专业列表
*/
export function GetMajor( headers:HeaderType,body:{ collegeCode:string|undefined; } ):Promise<any>{
    return(
        Axios({
            url:"/schoolMajor/list4collegeCode",
            method:"POST",
            headers,
            data:body
        })
    )
}

/**
 * 选择班级列表
*/
export function GetClass( headers:HeaderType,body:{ majorCode:string|undefined; } ):Promise<any>{
    return (
        Axios({
            url:"/schoolClasses/list4majorCode",
            method:"POST",
            headers,
            data:body
        })
    )
}

/**
 * 选择学期时间
*/
export function GetSemester( headers:HeaderType,body:{ classesCode:string|undefined; } ):Promise<any>{
    return (
        Axios({
            url:"/schoolSemester/list4classess",
            method:"POST",
            headers,
            data:body
        })
    )
}

/**
 * 修改信息
*/
export function SetClassSuc( headers:HeaderType,body:ChangeClassType ):Promise<any>{
    return (
        Axios({
            url:"/app/user/updateUserSchoolInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

/**
 * 请求课表
*/
export function GetAllClass( headers:HeaderType,body:{ weekPageNo:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/schoolTimetable/getTimetableByUserToken",
            method:"POST",
            headers,
            data:body
        })
    )
}
